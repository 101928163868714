
import { computed, defineComponent, PropType, reactive } from "vue";
import BaseModalSide from "@/components/common/BaseModalSide.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import { LectureScheduleQna, LecturesReservation } from "@/types/lectures";
import Avatar from "@/components/console/avatars/Avatar.vue";
import moment from "moment-timezone";
import openHourConst from "@/consts/openHour";
import QnaItem from "@/pages/console/OpenHourReservation/QnaItem/QnaItem.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default defineComponent({
  name: "QnaModal",
  components: { ButtonBasic, QnaItem, Avatar, CardBasic, BaseModalSide },
  props: {
    qnaList: {
      type: [] as PropType<LectureScheduleQna[]>,
      required: true,
    },
    reservation: {
      type: Object as PropType<LecturesReservation>,
      required: true,
    },
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const state = reactive({
      reservationTime: computed(() => {
        return `${moment(props.reservation.startAt).format(
          "YYYY.MM.DD HH:mm"
        )}~${moment(props.reservation.endAt)
          .tz(moment.tz.guess())
          .format("HH:mm z")}`;
      }),
      sessionTime: computed(() => {
        let sessionTime = moment(props.reservation.endAt).diff(
          props.reservation.startAt,
          "minutes"
        );
        let hour = Math.floor(sessionTime / 60);
        let minutes = sessionTime % 60;

        if (hour > 0) {
          return minutes === 0 ? `${hour}시간` : `${hour}시간 ${minutes}분`;
        } else {
          return `${minutes}분`;
        }
      }),
      status: computed(() => {
        return props.reservation.scheduleUser.status;
      }),
      statusText: computed(() => {
        if (state.status === openHourConst.reservationStatus.COMPLETED) {
          return "세션 예정";
        }
        if (state.status === openHourConst.reservationStatus.CANCELED) {
          return "세션 취소";
        }
        return "세션 종료";
      }),
      statusColor: computed(() => {
        if (state.status === openHourConst.reservationStatus.COMPLETED) {
          return "#00A3FF";
        }
        if (state.status === openHourConst.reservationStatus.CANCELED) {
          return "#D0D5DE";
        }
        return "#0D0D10";
      }),
    });

    const actions = {
      closeModal: () => {
        emit("closeModal");
      },
    };

    return { state, actions };
  },
});
