const openHourConst = {
  reservationStatus: {
    ALL: null,
    COMPLETED: "COMPLETED",
    FINISHED: "FINISHED",
    CANCELED: "CANCELED",
    NOSHOW: "NOSHOW",
  },
};

export default openHourConst;
