
import BaseModal from "@/components/common/BaseModal.vue";
import InputTextBox from "@/components/console/inputs/InputTextBox.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import { defineComponent, onMounted, PropType, reactive, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment-timezone";

export default defineComponent({
  name: "ReservationCancelModal",
  components: { ButtonBasic, BaseModal, InputTextBox },
  props: {
    lectureResourceId: {
      type: String as PropType<string>,
      required: true,
    },
    reservationResourceId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: ["closeModal", "confirm"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      reason: "",
      isActive: computed(() => {
        return state.reason.trim().length > 0;
      }),
    });

    onMounted(() => {
      console.log(props.lectureResourceId);
    });

    const actions = {
      closeModal: () => {
        emit("closeModal");
      },
      cancelReservation: () => {
        let eventDate = moment().format("YY-MM-DD HH:mm:ss");
        store
          .dispatch("lectures/cancelReservedSchedule", {
            lectureResourceId: props.lectureResourceId,
            reservationResourceId: props.reservationResourceId,
            data: {
              cancelReason: state.reason,
            },
          })
          .then(() => {
            emit("confirm", eventDate);
          });
      },
    };

    return { state, actions };
  },
});
