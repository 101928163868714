
import { computed, defineComponent, onMounted, PropType, reactive } from "vue";
import { LectureScheduleQna } from "@/types/lectures";
import Badge from "@/components/console/badges/Badge.vue";

export default defineComponent({
  name: "QnaItem",
  components: { Badge },
  props: {
    qna: {
      type: Object as PropType<LectureScheduleQna>,
      required: true,
    },
    number: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      answer: "",
      isAnswer: false,
    });

    onMounted(() => {
      state.answer = props.qna.answer
        ? props.qna.answer
        : "등록된 답변이 없어요";
      state.isAnswer = state.answer !== "등록된 답변이 없어요";
    });

    return { state };
  },
});
