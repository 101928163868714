
import { computed, defineComponent, onBeforeMount, reactive } from "vue";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import ButtonTab from "@/components/console/buttons/ButtonTab.vue";
import { useStore } from "vuex";
import openHourConst from "@/consts/openHour";
import ReservationItem from "@/pages/console/OpenHourReservation/ReservationItem/ReservationItem.vue";
import EmptyList from "@/components/console/empties/EmptyList.vue";
import Pagination from "@/components/console/pagination/Pagination.vue";
import PageLoading from "@/components/console/loadings/PageLoading.vue";

export default defineComponent({
  name: "OpenHourReservation",
  components: {
    PageLoading,
    Pagination,
    EmptyList,
    ReservationItem,
    ButtonTab,
    PageHeader,
  },
  setup() {
    const store = useStore();
    const lectureResourceId: string = store.getters["auth/user"].lecture
      ? store.getters["auth/user"].lecture.resourceId
      : "";

    const state = reactive({
      pageLoading: true,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      lectureReservationsMeta: computed(() => {
        return store.getters["lectures/lectureReservationsMeta"];
      }),
      reservations: computed(() => {
        return store.getters["lectures/lecturesReservations"];
      }),
      reservationCount: computed(() => {
        return store.getters["lectures/lectureReservationCount"];
      }),
      filters: {
        startAt: "",
        endAt: "",
        status: openHourConst.reservationStatus.COMPLETED,
        page: 1,
        orders: "",
      },
      prevStatus: "",
      showQnaModal: false,
      emptyInfoText: computed(() => {
        let status = state.filters.status;
        if (status === null) {
          return "예약된 세션이 없어요";
        } else if (status === openHourConst.reservationStatus.COMPLETED) {
          return "예정된 세션이 없어요";
        } else if (status === openHourConst.reservationStatus.FINISHED) {
          return "종료된 세션이 없어요";
        } else if (
          state.filters.status === openHourConst.reservationStatus.CANCELED
        ) {
          return "취소된 세션이 없어요";
        } else {
          return "노쇼된 세션이 없어요";
        }
      }),
    });

    onBeforeMount(async () => {
      getLecture();
      getReservationCounts();
      await getReservations();
      state.pageLoading = false;
    });

    const getLecture = () => {
      store.dispatch("lectures/getLecture", {
        resourceId: state.user.lecture.resourceId,
      });
    };

    const getReservationCounts = () => {
      store.dispatch("lectures/getReservationCounts", {
        lectureResourceId: lectureResourceId,
      });
    };

    const getReservations = async (): Promise<void> => {
      await store
        .dispatch("lectures/getLectureReservations", {
          lectureResourceId: lectureResourceId,
          data: state.filters,
        })
        .then(() => {
          state.filters = {
            ...state.filters,
            ...state.lectureReservationsMeta.filters,
          };
          state.filters.page =
            state.lectureReservationsMeta.pagination.currentPage;
        });
    };

    const resetFilters = (status) => {
      if (state.prevStatus !== status) {
        state.prevStatus = status;
        state.filters.startAt = "";
        state.filters.endAt = "";
        state.filters.status = status;
        state.filters.page = 1;
      }
    };

    const actions = {
      setFilterStatus: async (status = "") => {
        resetFilters(status);

        state.filters.orders =
          status === openHourConst.reservationStatus.COMPLETED ? "asc" : "desc";

        state.pageLoading = true;
        state.filters.status = status;
        await getReservations();
        await getReservationCounts();
        state.pageLoading = false;
      },
      getFilteredReservations: async (data) => {
        state.pageLoading = true;
        state.filters.page = data;
        await getReservations();
        state.pageLoading = false;
      },
      updateReservations: async () => {
        state.pageLoading = true;
        // note reservations 목록을 가져올 때, 페이지당 15개씩 나오며, 전체 목록이 16개 31개 46개 라면 마지막 페이지는 한개의 목록만 노출됨.
        // note 이때 예약 취소하고 getReservations()를 해줄 때 filters.page를 업데이트 해주지 않으면
        // note 목록의 갯수와 맞지 않는 페이지를 요청하게 되서 데이터가 안나옴
        if (state.reservations.length === 1 && state.filters.page > 1) {
          state.filters.page -= 1;
        }
        await getReservations();
        await getReservationCounts();
        state.pageLoading = false;
      },
      // todo api 수정 필요, reservation.feedbackCount의 값 업데이트
      updateReservation: async () => {
        await getReservations();
      },
    };

    return {
      state,
      actions,
      getReservations,
      lectureResourceId,
      openHourConst,
    };
  },
});
