
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  reactive,
} from "vue";
import { LecturesReservation } from "@/types/lectures";
import BaseModalSide from "@/components/common/BaseModalSide.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import moment from "moment-timezone";
import openHourConst from "@/consts/openHour";
import Avatar from "@/components/console/avatars/Avatar.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputTextBoxNew from "@/components/console/inputs/InputTextBoxNew.vue";
import { useStore } from "vuex";

type AnswerForm = {
  answer: string;
  questionResourceId?: string;
};
export default defineComponent({
  name: "FeedbackModal",
  components: {
    InputTextBoxNew,
    ButtonBasic,
    Avatar,
    CardBasic,
    BaseModalSide,
  },
  props: {
    reservation: {
      type: Object as PropType<LecturesReservation>,
      required: true,
    },
  },
  emits: ["saveFeedback", "closeModal"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      reservationTime: computed(() => {
        return `${moment(props.reservation.startAt).format(
          "YYYY.MM.DD HH:mm"
        )}~${moment(props.reservation.endAt)
          .tz(moment.tz.guess())
          .format("HH:mm z")}`;
      }),
      sessionTime: computed(() => {
        let sessionTime = moment(props.reservation.endAt).diff(
          props.reservation.startAt,
          "minutes"
        );
        let hour = Math.floor(sessionTime / 60);
        let minutes = sessionTime % 60;

        if (hour > 0) {
          return minutes === 0 ? `${hour}시간` : `${hour}시간 ${minutes}분`;
        } else {
          return `${minutes}분`;
        }
      }),
      status: computed(() => {
        return props.reservation.scheduleUser.status;
      }),
      statusText: computed(() => {
        if (state.status === openHourConst.reservationStatus.COMPLETED) {
          return "세션 예정";
        }
        if (state.status === openHourConst.reservationStatus.CANCELED) {
          return "세션 취소";
        }
        return "세션 종료";
      }),
      statusColor: computed(() => {
        if (state.status === openHourConst.reservationStatus.COMPLETED) {
          return "#00A3FF";
        }
        if (state.status === openHourConst.reservationStatus.CANCELED) {
          return "#D0D5DE";
        }
        return "#0D0D10";
      }),
      feedback: computed(() => {
        return store.getters["lectures/lectureReservationFeedback"];
      }),
      feedbackForm: [] as AnswerForm[],
      disabledSaveBtn: computed(() => {
        let count = 0;
        let answers = [false, false];
        for (let i = 0; i < state.feedback.length; i++) {
          if (state.feedbackForm[i].answer === state.feedback[i].answer) {
            count++;
          }
          if (state.feedbackForm[i].answer.length > 0) {
            answers[i] = true;
          }
        }
        return count === 2 || answers.includes(false);
      }),
    });

    onBeforeMount(() => {
      for (let i = 0; i < state.feedback.length; i++) {
        state.feedbackForm[i] = {
          answer: state.feedback[i].answer || "",
          questionResourceId: state.feedback[i].resourceId,
        };
      }
    });

    const actions = {
      saveFeedback: () => {
        emit("saveFeedback", state.feedbackForm);
      },
      closeModal: () => {
        emit("closeModal");
      },
    };

    return { state, actions };
  },
});
