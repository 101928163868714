import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ab5b442"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_basic = _resolveComponent("button-basic")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    title: "예약을 취소할 수 없어요.",
    "sub-title": "세션이 시작하기 24시간 전에는 예약을 취소할수 없어요. 예약을 취소해야 한다면 고객센터로 문의해주세요.",
    onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.closeModal()))
  }, {
    modalBody: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_button_basic, {
          text: "예약 취소 문의",
          "bg-color": "#ECF1F4",
          color: "#0d0d0d",
          onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.showChannelIO()))
        }),
        _createVNode(_component_button_basic, {
          text: "확인",
          onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actions.closeModal()))
        })
      ])
    ]),
    _: 1
  }))
}