import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0559abd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qna-item" }
const _hoisted_2 = { class: "question" }
const _hoisted_3 = { class: "text-purple-20" }
const _hoisted_4 = { class: "answer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_badge = _resolveComponent("badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_badge, {
        class: "label",
        color: "#B28BFF",
        text: `질문 ${_ctx.number}`
      }, null, 8, ["text"]),
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.qna.localization.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", {
        class: _normalizeClass(["b-text-1", { 'text-gray-third': !_ctx.state.isAnswer }])
      }, _toDisplayString(_ctx.state.answer), 3)
    ])
  ]))
}