
import { computed, defineComponent, PropType, reactive } from "vue";
import { LecturesReservation } from "@/types/lectures";
import Avatar from "@/components/console/avatars/Avatar.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ButtonDropdown from "@/pages/console/ContentShow/ButtonDropdown/ButtonDropdown.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdownItem from "@/components/console/buttons/ButtonDropdownItem.vue";
import moment from "moment-timezone";
import openHourConst from "@/consts/openHour";
import { useStore } from "vuex";
import QnaModal from "@/pages/console/OpenHourReservation/QnaModal/QnaModal.vue";
import ReservationCancelModal from "@/pages/console/OpenHourReservation/ReservationCancelModal/ReservationCancelModal.vue";
import CancelDisabledModal from "@/pages/console/OpenHourReservation/CancelDisableModal/CancelDisableModal.vue";
import FeedbackModal from "@/pages/console/OpenHourReservation/FeedbackModal/FeedbackModal.vue";
import swal from "@/helper/swal.js";
import { dateFormat } from "../../../../helper/date.js";
import { copyEventObj, pushDataLayer } from "@/helper/dataLayerConsole";
import SsoService from "../../../../services/SsoService.js";

export default defineComponent({
  name: "ReservationItem",
  components: {
    FeedbackModal,
    CancelDisabledModal,
    ReservationCancelModal,
    QnaModal,
    ButtonDropdownItem,
    MoreVertiIcon,
    ButtonText,
    ButtonDropdown,
    ButtonBasic,
    Avatar,
  },
  props: {
    reservation: {
      type: Object as PropType<LecturesReservation>,
      required: true,
    },
    lectureResourceId: {
      type: String as PropType<string>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  // TODO
  emits: ["updateReservations", "updateReservation"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      isStaff: computed(() => {
        return (
          store.getters["auth/user"].isStaff ||
          store.getters["auth/user"].isAdmin
        );
      }),
      reservationTime: computed(() => {
        return `${moment(props.reservation.startAt).format(
          "YYYY.MM.DD HH:mm"
        )}~${moment(props.reservation.endAt)
          .tz(moment.tz.guess())
          .format("HH:mm z")}`;
      }),
      sessionTime: computed(() => {
        let sessionTime = moment(props.reservation.endAt).diff(
          props.reservation.startAt,
          "minutes"
        );

        let hour = Math.floor(sessionTime / 60);
        let minutes = sessionTime % 60;

        if (hour > 0) {
          return minutes === 0 ? `${hour}시간` : `${hour}시간 ${minutes}분`;
        } else {
          return `${minutes}분`;
        }
      }),
      status: computed(() => {
        return props.reservation.scheduleUser.status;
      }),
      isFeedback: computed(() => {
        return props.reservation.isFeedback;
      }),
      reservation: computed(() => {
        return props.reservation;
      }),
      statusText: computed(() => {
        if (state.status === openHourConst.reservationStatus.COMPLETED) {
          return "세션 예정";
        }
        //note 취소 scheduleUser.status가 "CANCELED" 이고, cancelCode "C".
        if (
          state.status === openHourConst.reservationStatus.CANCELED &&
          state.reservation.scheduleUser.cancelCode === "C"
        ) {
          return "세션 취소";
        }
        //note 노쇼 scheduleUser.status가 "CANCELED" 이고, cancelCode "NC" or "N".
        if (
          state.status === openHourConst.reservationStatus.CANCELED &&
          state.reservation.scheduleUser.cancelCode !== "C"
        ) {
          return "세션 노쇼";
        }
        return "세션 종료";
      }),
      isCompleted: computed(() => {
        return state.status === openHourConst.reservationStatus.COMPLETED;
      }),
      statusColor: computed(() => {
        if (state.status === openHourConst.reservationStatus.COMPLETED) {
          return "#00A3FF";
        }
        if (state.status === openHourConst.reservationStatus.CANCELED) {
          return "#D0D5DE";
        }
        return "#0D0D10";
      }),
      showQnaModal: false,
      qnaList: computed(() => {
        return store.getters["lectures/lectureScheduleQna"];
      }),
      showReservationCancelModal: false,
      showCancelDisableModal: false,
      showFeedbackModal: false,
      feedback: computed(() => {
        return store.getters["lectures/lectureReservationFeedback"];
      }),
    });

    const getLectureScheduleQna = async (
      reservationResourceId
    ): Promise<void> => {
      await store.dispatch("lectures/getLectureScheduleQna", {
        lectureResourceId: props.lectureResourceId,
        reservationResourceId: reservationResourceId,
      });
    };

    const getLectureScheduleFeedback = async (
      reservationResourceId
    ): Promise<void> => {
      await store.dispatch("lectures/getLectureScheduleFeedback", {
        lectureResourceId: props.lectureResourceId,
        reservationResourceId: reservationResourceId,
      });
    };

    const saveFeedback = async (answerForm): Promise<void> => {
      await store
        .dispatch("lectures/postLectureScheduleFeedback", {
          lectureResourceId: props.lectureResourceId,
          reservationResourceId: props.reservation.resourceId,
          data: {
            answers: answerForm,
          },
        })
        .then(() => {
          swal.messageAlert("피드백을 완료하였습니다.");
          emit("updateReservation");
        });
    };

    const setScheduleCanceledDataLayer = (schedule, eventDate) => {
      let canceledSession = copyEventObj("cancel");

      let user = store.getters["auth/user"];
      let lecture = store.getters["lectures/getLecture"];

      canceledSession["studio_session_creator_canceled"].creator_id =
        user.resourceId;
      canceledSession["studio_session_creator_canceled"].creator_account =
        user.email;

      let reservationTime = moment(props.reservation.endAt).diff(
        props.reservation.startAt,
        "minutes"
      );

      let reservationCounts = (reservationTime + 3) / 30;

      for (let i = 0; i < lecture.tags.length; i++) {
        canceledSession["studio_session_creator_canceled"].creator_category[i] =
          {
            category: lecture.tags[i].name,
            type: lecture.tags[i].type,
          };
      }
      canceledSession["studio_session_creator_canceled"].creator_name =
        user.userProfile.displayName;
      canceledSession["studio_session_creator_canceled"].creator_page =
        user.lecture.title;
      canceledSession[
        "studio_session_creator_canceled"
      ].creator_url = `${process.env.VUE_APP_OPEN_HOUR_URL}/lecture/${user.lecture.resourceId}`;

      canceledSession[
        "studio_session_creator_canceled"
      ].creator_canceled_session_quantity = reservationCounts;
      canceledSession[
        "studio_session_creator_canceled"
      ].creator_canceled_coin_quantity =
        user.userProfile.defaultCoin * reservationCounts;
      canceledSession[
        "studio_session_creator_canceled"
      ].creator_canceled_session_date = eventDate;

      canceledSession["studio_session_creator_canceled"].session_info[0] = {
        session_id: schedule.resourceId,
        session_coin: user.userProfile.defaultCoin,
        member_id: schedule.scheduleUser.user.resourceId,
        member_account: schedule.scheduleUser.user.email,
        member_signup_date: dateFormat(
          schedule.scheduleUser.user.createdAt,
          "YY-MM-DD HH:mm:ss"
        ),
        session_reserved_date: dateFormat(
          schedule.scheduleUser.createdAt,
          "YY-MM-DD HH:mm:ss"
        ),
        session_start_date: dateFormat(schedule.startAt, "YY-MM-DD HH:mm:ss"),
        session_end_date: dateFormat(schedule.endAt, "YY-MM-DD HH:mm:ss"),
      };

      pushDataLayer(canceledSession);
    };

    const onBtnSSO = () => {
      let meetingResourceId = state.reservation?.meeting.resourceId;

      const redirect = `${process.env.VUE_APP_LIVE_URL}/live/${meetingResourceId}`;

      const ssoEndPoint = `${process.env.VUE_APP_LIVE_URL}/auth/sso`;

      SsoService.redirect(ssoEndPoint, redirect);
    };

    const actions = {
      openQnaModal: async () => {
        // 모달이 열어진 상태에서, 모달을 여는 버튼을 눌렀을 때, auto-hide 기능과 오버랩되는거 방지.
        if (state.showQnaModal) {
          state.showQnaModal = false;
          return;
        }
        await getLectureScheduleQna(props.reservation.resourceId);
        state.showQnaModal = true;
      },
      closeQnaModal: () => {
        state.showQnaModal = false;
      },
      reservationCancel: () => {
        if (moment(props.reservation.startAt).diff(moment(), "hours") < 24) {
          state.showCancelDisableModal = true;
        } else {
          state.showReservationCancelModal = true;
        }
      },
      closeCancelDisableModal: () => {
        state.showCancelDisableModal = false;
      },
      closeReservationCancelModal: () => {
        state.showReservationCancelModal = false;
      },
      cancelReservation: (eventDate) => {
        setScheduleCanceledDataLayer(props.reservation, eventDate);
        state.showReservationCancelModal = false;
        swal.messageAlert("예약이 취소되었습니다.");
        emit("updateReservations");
      },
      openFeedbackModal: async () => {
        // 모달이 열어진 상태에서, 모달을 여는 버튼을 눌렀을 때, auto-hide 기능과 오버랩되는거 방지.
        if (state.showFeedbackModal) {
          state.showFeedbackModal = false;
          return;
        }
        await getLectureScheduleFeedback(props.reservation.resourceId);
        state.showFeedbackModal = true;
      },
      closeFeedbackModal: () => {
        state.showFeedbackModal = false;
      },
      onBtnSSO: () => {
        onBtnSSO();
      },
    };

    return { state, actions, openHourConst, saveFeedback };
  },
});
